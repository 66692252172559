var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{staticClass:"mt-0 pt-0",attrs:{"timeout":3000,"top":"","color":"red"},model:{value:(_vm.snackbarProjectNotReady),callback:function ($$v) {_vm.snackbarProjectNotReady=$$v},expression:"snackbarProjectNotReady"}},[_c('span',{staticClass:"black--text body-2 font-weight-medium"},[_vm._v("Project not ready for public access")]),_c('v-btn',{staticClass:"black--text ml-16",attrs:{"text":"","icon":""},on:{"click":function($event){_vm.snackbarProjectNotReady = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-row',{staticClass:"ma-0 pa-0 pt-16 px-6 pb-10"},[_c('v-col',{staticClass:"ma-0 pa-0"},[_c('h1',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.mdAndUp),expression:"$vuetify.breakpoint.mdAndUp"}],staticClass:"display-3 font-weight-black"},[_vm._v("Code that makes a difference.")]),_c('h1',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}],staticClass:"display-1 font-weight-black text-center"},[_vm._v("Code that makes a difference.")])])],1),_c('v-row',{staticClass:"ma-0 pa-0 pt-10",attrs:{"justify":"center"}},[_c('v-col',{staticClass:"ma-0 pa-0 px-6 pb-10",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border-top":"8px solid #272727 !important"},attrs:{"elevation":hover ? 24 : 8,"height":"300","ripple":"","hover":"","href":"https://www.artiusid.dev/"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center overline",staticStyle:{"font-size":"18px!important"}},[_vm._v("Proven Identity")])],1),_c('v-divider',{staticClass:"mx-8"}),_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/Q5id.png"),"height":"100","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"body-1 px-10 text-center"},[_vm._v("Proofing solution that vets potential employees using their proven identity")])])],1)]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0 px-6 pb-10",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border-top":"8px solid #272727 !important"},attrs:{"elevation":hover ? 24 : 8,"height":"300","ripple":"","hover":"","href":"https://www.artiusid.dev/"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center overline",staticStyle:{"font-size":"18px!important"}},[_vm._v("Guardian")])],1),_c('v-divider',{staticClass:"mx-8"}),_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/Guardian.png"),"height":"100","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"body-1 px-10 text-center"},[_vm._v("A new app revolutionizing how missing people can be found")])])],1)]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0 px-6 pb-10",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border-top":"8px solid #272727 !important"},attrs:{"elevation":hover ? 24 : 8,"height":"300","ripple":"","hover":"","href":"https://loginshield.com"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center overline",staticStyle:{"font-size":"18px!important"}},[_vm._v("LoginShield")])],1),_c('v-divider',{staticClass:"mx-8"}),_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/LoginShield.png"),"height":"100","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"body-1 px-10 text-center"},[_vm._v("Two-factor authentication app with phishing protection")])])],1)]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0 px-6 pb-10",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border-top":"8px solid #272727 !important"},attrs:{"elevation":hover ? 24 : 8,"height":"300","ripple":"","hover":"","href":"https://github.com/taylormoyle/TrueSight"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center overline",staticStyle:{"font-size":"18px!important"}},[_vm._v("TrueSight")])],1),_c('v-divider',{staticClass:"mx-8"}),_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/TrueSight.jpg"),"height":"100","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"body-1 px-10 text-center"},[_vm._v("Facial recognition AI")])])],1)]}}])})],1),_c('v-col',{staticClass:"ma-0 pa-0 px-6",attrs:{"cols":"12","xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover },staticStyle:{"border-top":"8px solid #272727 !important"},attrs:{"elevation":hover ? 24 : 8,"height":"300","ripple":"","hover":"","href":"https://github.com/cryptiumtech/message-client-android-java"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-card-title',{staticClass:"text-center overline",staticStyle:{"font-size":"18px!important"}},[_vm._v("Vital")])],1),_c('v-divider',{staticClass:"mx-8"}),_c('v-row',{staticClass:"py-8",attrs:{"justify":"center"}},[_c('v-img',{attrs:{"src":require("@/assets/Vital.png"),"height":"100","contain":""}})],1),_c('v-row',{attrs:{"justify":"center"}},[_c('p',{staticClass:"body-1 px-10 text-center"},[_vm._v("Messaging app that prevents unwanted notifications")])])],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }