<template>
    <div>
        <v-snackbar v-model="snackbarProjectNotReady" :timeout="3000" top color="red" class="mt-0 pt-0">
            <span class="black--text body-2 font-weight-medium">Project not ready for public access</span>
            <v-btn text class="black--text ml-16" @click="snackbarProjectNotReady = false" icon ><v-icon>mdi-close</v-icon></v-btn>
        </v-snackbar>
        <v-row class="ma-0 pa-0 pt-16 px-6 pb-10">
            <v-col class="ma-0 pa-0">
                <h1 v-show="$vuetify.breakpoint.mdAndUp" class="display-3 font-weight-black">Code that makes a difference.</h1>
                <h1 v-show="$vuetify.breakpoint.smAndDown" class="display-1 font-weight-black text-center">Code that makes a difference.</h1>
            </v-col>
        </v-row>
        <v-row class="ma-0 pa-0 pt-10" justify="center">
           <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://www.artiusid.dev/" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">Proven Identity</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/Q5id.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Proofing solution that vets potential employees using their proven identity</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://www.artiusid.dev/" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">Guardian</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/Guardian.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">A new app revolutionizing how missing people can be found</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://loginshield.com" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">LoginShield</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/LoginShield.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Two-factor authentication app with phishing protection</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6 pb-10">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://github.com/taylormoyle/TrueSight" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">TrueSight</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/TrueSight.jpg" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Facial recognition AI</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="4" xl="4" class="ma-0 pa-0 px-6">
                <v-hover v-slot:default="{ hover }">
                    <v-card :class="{ 'on-hover': hover }" :elevation="hover ? 24 : 8" height="300" ripple hover href="https://github.com/cryptiumtech/message-client-android-java" style="border-top: 8px solid #272727 !important;">
                        <v-row justify="center">
                            <v-card-title class="text-center overline" style="font-size: 18px!important;">Vital</v-card-title>
                        </v-row>
                        <v-divider class="mx-8"></v-divider>
                        <v-row justify="center" class="py-8">
                            <v-img src="@/assets/Vital.png" height="100" contain></v-img>
                        </v-row>
                        <v-row justify="center">
                            <p class="body-1 px-10 text-center">Messaging app that prevents unwanted notifications</p>
                        </v-row>
                    </v-card>
                </v-hover>
            </v-col>
        </v-row>
    </div>
</template>

<script>
export default {
    data() {
        return {    
            snackbarProjectNotReady: false,
        };
    },

    methods: {
        projectNotReady() {
            this.snackbarProjectNotReady = true;
        }
    },
}
</script>